import InlineSVG from "react-inlinesvg"

import { SeparatorWithChildren } from "@fourel/ui"

import PlaneIcon from "#components/images/plane.svg"
import { formatDate } from "#components/utils/format-date-time.js"

import type { RequestedLeg, TripType } from "../page.js"

export const TripSummary = ({
  queriedTrip,
  legIndex,
}: {
  queriedTrip: TripType
  legIndex: number
}) => {
  const requestedLeg: RequestedLeg = queriedTrip?.requestedLegs[legIndex]

  return requestedLeg ? (
    <header key={2} className="flex w-full flex-col gap-6 rounded-md bg-violet-50 p-4">
      <section className="flex w-full items-center gap-7 lg:w-2/3 xl:w-1/3">
        <div className="flex flex-col items-center gap-2 md:items-start">
          <span className="text-xl font-bold md:text-3xl">
            {requestedLeg.departureAirport.iataCode
              ? requestedLeg.departureAirport.iataCode
              : requestedLeg.departureAirport.icaoCode}
          </span>
          <span className="w-20 text-xs font-medium text-slate-500 dark:text-slate-400">
            {requestedLeg.departureDate
              ? formatDate(requestedLeg.departureDate)
              : "No arrival date"}
          </span>
        </div>
        <div className="w-full">
          <SeparatorWithChildren
            children={<InlineSVG src={PlaneIcon} />}
            className="flex-1 bg-slate-500"
          />
        </div>
        <div className="flex flex-col gap-2 self-start md:flex-row">
          <span className="text-xl font-bold md:text-3xl">
            {requestedLeg.arrivalAirport.iataCode
              ? requestedLeg.arrivalAirport.iataCode
              : requestedLeg.arrivalAirport.icaoCode}
          </span>
        </div>
      </section>
      <section className="grid w-full grid-cols-2 flex-wrap gap-4 text-sm text-black md:flex md:flex-row lg:gap-6 xl:gap-8">
        <div className="flex items-center gap-4 md:items-start [&>*]:md:text-start">
          <h3 className="text-muted-foreground font-medium">Client</h3>
          <h3>{queriedTrip?.client?.name || ""}</h3>
        </div>
        <div className="flex items-center gap-4 md:items-start [&>*]:md:text-start">
          <h3 className="text-muted-foreground font-medium">Pax</h3>
          <h3>{requestedLeg.pax}</h3>
        </div>
        <div className="flex items-center gap-4 md:items-start [&>*]:md:text-start">
          <h3 className="text-muted-foreground font-medium">Aircraft preferences</h3>
          <h3>{requestedLeg.aircraftClass}</h3>
        </div>
      </section>
    </header>
  ) : null
}
