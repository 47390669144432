import React from "react"
import type { ControllerRenderProps } from "react-hook-form"

import { FormControl, FormItem, FormLabel, FormMessage, Input } from "@fourel/ui"

import type { AircraftFormType } from "../create-aircraft-modal.js"

interface YearOfBuildInputProps {
  field: ControllerRenderProps<AircraftFormType, "yearOfBuild">
  placeholder: string
  label: string
  setValue: (field: "yearOfBuild", value: string) => void
}

export const YearOfBuildInput = ({
  field,
  placeholder,
  label,
  setValue,
}: YearOfBuildInputProps) => {
  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.value)
    setValue("yearOfBuild", event.target.value)
  }

  return (
    <FormItem className="w-full">
      <div className="flex items-center justify-between gap-2">
        <FormLabel className="w-1/2 text-xs">{label}</FormLabel>
        <FormControl className="w-full text-xs">
          <Input
            placeholder={placeholder}
            {...field}
            value={field.value || ""}
            onChange={(event) => inputHandler(event)}
          />
        </FormControl>
      </div>
      <FormMessage className="text-xs" />
    </FormItem>
  )
}
