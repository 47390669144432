import type { VariablesOf } from "@graphql-typed-document-node/core"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQuery } from "@tanstack/react-query"
import type { GraphQLError } from "graphql/error"
import PlusIcon from "lucide-static/icons/plus.svg"
import TrashIcon from "lucide-static/icons/trash-2.svg"
import React, { useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import InlineSVG from "react-inlinesvg"
import * as z from "zod"

import {
  Button,
  Checkbox,
  cn,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  toast,
  ToastAction,
  ToggleGroup,
  ToggleGroupItem,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { AircraftClassSelect } from "#pages/dashboard/components/aircraft-category-select.js"
import { ClientSelect } from "#pages/dashboard/components/client-select.js"
import { TimeInput } from "#pages/dashboard/components/time-input.js"
import { useParsedTripStore } from "#store/quick-trip-store.js"

import { AirportSelect } from "./airport-select.js"
import { DatePicker } from "./date-picker.js"
import { PaxSelect } from "./pax-count.js"

const TripsCreateDocument = graphql(/* GraphQL */ `
  mutation TripsCreateQuick($input: MutationTripsCreateInput!) {
    tripsCreate(input: $input) {
      id
      avinodeTrips {
        searchInAvinode
      }
    }
  }
`)

const AvinodeTokenExistsDocument = graphql(/* GraphQL */ `
  query AvinodeTokenExistsForTrips {
    avinodeTokenExists
  }
`)

const airportSchema = z.object({
  id: z.string().trim().min(1, { message: "Id is required" }),
  label: z.string().trim().min(1, { message: "Label is required" }),
  code: z.string().trim().min(1, { message: "Code is required" }),
})

const paxSchema = z
  .string()
  .trim()
  .refine(
    (value) => {
      const parsedValue = parseInt(value, 10)
      return !isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 50
    },
    {
      message: "Pax is required",
    },
  )

const legSchema = z.object({
  departureAirport: airportSchema.refine((data) => data.id && data.label, {
    message: "Departure airport is required",
  }),
  arrivalAirport: airportSchema.refine((data) => data.id && data.label, {
    message: "Arrival airport is required",
  }),
  departureDate: z
    .union([z.date().nullable(), z.string().nullable()])
    .refine((val) => val !== null && val !== "", {
      message: "Departure date is required",
    }),
  estimatedTimeOfDeparture: z.string().nullable(),
  pax: paxSchema,
  departure: z.enum(["departure", "arrival"]).default("departure"),
})

const tripSchema = z.object({
  aircraftClass: z.string().min(1, { message: "Aircraft class is required" }).max(150),
  legs: legSchema.array(),
  avinode: z.boolean().default(true),
  clientId: z.string().optional(),
})

export type TripFormType = z.infer<typeof tripSchema>
type LegFormType = z.infer<typeof legSchema>

const emptyLegData: LegFormType = {
  departureAirport: {
    label: "",
    id: "",
    code: "",
  },
  arrivalAirport: {
    label: "",
    id: "",
    code: "",
  },
  departureDate: "",
  estimatedTimeOfDeparture: "12:00",
  pax: "1",
  departure: "departure",
}

export const QuickTripRequest = () => {
  const { requestedLegs, clearParsedTrip } = useParsedTripStore()

  const { data } = useQuery({
    queryKey: ["avinodeOrganizationToken"],
    queryFn: () => client.request(AvinodeTokenExistsDocument),
  })

  const form = useForm<TripFormType>({
    resolver: zodResolver(tripSchema),
    defaultValues: {
      aircraftClass: "",
      legs: [{ ...emptyLegData }],
      avinode: data?.avinodeTokenExists || false,
    },
    reValidateMode: "onSubmit",
  })

  const { reset } = form

  useEffect(() => {
    if (requestedLegs.length) {
      reset({
        aircraftClass: requestedLegs[0].aircraftClass,
        legs: requestedLegs.map((leg) => ({
          departureAirport:
            leg.departureAirports.length === 1
              ? {
                  id: leg.departureAirports[0].id,
                  label: leg.departureAirports[0].name,
                  code:
                    leg.departureAirports[0].icaoCode ||
                    leg.departureAirports[0].iataCode!,
                }
              : { id: "", label: "", code: "" },

          arrivalAirport:
            leg.arrivalAirports.length === 1
              ? {
                  id: leg.arrivalAirports[0].id,
                  label: leg.arrivalAirports[0].name,
                  code:
                    leg.arrivalAirports[0].icaoCode || leg.arrivalAirports[0].iataCode!,
                }
              : { id: "", label: "", code: "" },
          departure: leg.departureDate
            ? "departure"
            : leg.arrivalDate
              ? "arrival"
              : "departure",
          departureDate: leg.departureDate ?? leg.arrivalDate ?? null,
          estimatedTimeOfDeparture: leg.departureDate
            ? leg.departureDate.split("T")[1]?.slice(0, 5)
            : leg.arrivalDate
              ? leg.arrivalDate.split("T")[1]?.slice(0, 5)
              : null,
          pax: String(leg.pax),
        })),
      })
    }
  }, [reset, requestedLegs])

  const {
    fields: legFields,
    append,
    remove,
  } = useFieldArray({
    name: "legs",
    control: form.control,
  })

  const addLegFormHandler = () => {
    const legs = form.getValues("legs")
    const lastLeg = legs[legs.length - 1]

    const newLeg = {
      ...emptyLegData,
      departureDate: legs.length > 0 ? lastLeg.departureDate : new Date(),
      departureAirport:
        legs.length > 0 && lastLeg.arrivalAirport.id
          ? lastLeg.arrivalAirport
          : { id: "", label: "", code: "" },
      departure: lastLeg.departure || "departure",
      pax: legs.length > 0 ? lastLeg.pax : "1",
    }
    append(newLeg)
  }

  const removeLegFormHandler = (legIndex: number) => {
    remove(legIndex)
  }

  const { mutate, isPending } = useMutation({
    mutationFn: (variables: VariablesOf<typeof TripsCreateDocument>) =>
      client.request(TripsCreateDocument, variables),
    onSuccess: (res) => {
      void clearParsedTrip()
      void form.reset()
      const url = res.tripsCreate.avinodeTrips[0].searchInAvinode
      if (url) {
        window.open(url, "_blank")
      }
    },
    onError: async (error) => {
      const gqlError = error as GraphQLError
      const errorMessage =
        // @ts-expect-error graphql-request doesn't export types, so we hack around it
        gqlError.response?.errors?.[0]?.message ||
        "An error occurred while creating a Trip."

      toast({
        variant: "destructive",
        title: "Error!",
        description: errorMessage,
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const onSubmitHandler = (values: TripFormType) => {
    const legsArray = values.legs.map((item, index) => {
      const [hours, minutes] = (item.estimatedTimeOfDeparture || "12:00")
        .split(":")
        .map(Number)

      const date = new Date(item.departureDate!) as Date
      const departureDate = new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          hours,
          minutes,
        ),
      ).toISOString()
      const arrivalDate = item.departure === "arrival" ? departureDate : null
      const estimatedTimeOfArrival =
        item.departure === "arrival" ? item.estimatedTimeOfDeparture : null

      return {
        aircraftClass: values.aircraftClass!,
        sequenceNumber: index,
        arrivalDate,
        estimatedTimeOfArrival,
        departureDate,
        estimatedTimeOfDeparture: item.estimatedTimeOfDeparture || "12:00:00",
        pax: +item.pax,
        departureAirportId: item.departureAirport.id,
        arrivalAirportId: item.arrivalAirport.id,
      }
    })

    mutate({
      input: {
        trip: {
          status: "pending",
          clientId: values.clientId || "",
        },
        legs: legsArray,
        avinode: values.avinode || false,
        combine: false,
      },
    })
  }

  return (
    <div className="relative flex flex-col gap-2">
      <h4 className="text-xl">Quick start</h4>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmitHandler)}
          className="flex w-full flex-col items-start justify-start gap-4"
        >
          <div className="flex w-full gap-6">
            <FormField
              control={form.control}
              name="clientId"
              render={({ field }) => (
                <ClientSelect field={field} setValue={form.setValue} />
              )}
            />
            <FormField
              control={form.control}
              name="aircraftClass"
              render={({ field }) => <AircraftClassSelect field={field} />}
            />
          </div>
          <div className="flex w-full flex-col rounded-sm border p-2">
            {legFields.map((_, index, self) => {
              const isLastLeg = index === self.length - 1
              return (
                <div
                  key={index}
                  className={cn(
                    "flex w-full flex-col gap-5",
                    !isLastLeg && "border-b border-gray-200 pb-6",
                  )}
                >
                  <div className="flex w-full items-center justify-between">
                    <span>Leg {index + 1}</span>
                    {self.length !== 1 && index !== 0 && (
                      <Button
                        type="button"
                        onClick={() => removeLegFormHandler(index)}
                        variant="ghost"
                      >
                        {" "}
                        <InlineSVG src={TrashIcon} className="w-4" />
                      </Button>
                    )}
                  </div>
                  <div className="flex w-full justify-between gap-6">
                    <FormField
                      control={form.control}
                      name={`legs.${index}.departureAirport`}
                      render={({ field }) => (
                        <AirportSelect
                          field={field}
                          fieldName={`legs.${index}.departureAirport`}
                          placeholder="From"
                          setValue={form.setValue}
                          airports={
                            (requestedLegs.length &&
                              requestedLegs[index]?.departureAirports) ||
                            []
                          }
                        />
                      )}
                    />
                    <FormField
                      control={form.control}
                      name={`legs.${index}.arrivalAirport`}
                      render={({ field }) => (
                        <AirportSelect
                          field={field}
                          fieldName={`legs.${index}.arrivalAirport`}
                          placeholder="To"
                          setValue={form.setValue}
                          airports={
                            (requestedLegs.length &&
                              requestedLegs[index]?.arrivalAirports) ||
                            []
                          }
                        />
                      )}
                    />
                  </div>
                  <div className="flex w-full gap-6">
                    <div className="-mt-2">
                      <FormField
                        control={form.control}
                        name={`legs.${index}.departure`}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-xs font-medium text-gray-500">
                              Departure/Arrival
                            </FormLabel>
                            <FormControl>
                              <ToggleGroup
                                type="single"
                                onValueChange={field.onChange}
                                value={field.value}
                                disabled={!data?.avinodeTokenExists}
                              >
                                <ToggleGroupItem
                                  value="departure"
                                  aria-label="Toggle departure"
                                >
                                  Departure
                                </ToggleGroupItem>
                                <ToggleGroupItem
                                  value="arrival"
                                  aria-label="Toggle arrival"
                                >
                                  Arrival
                                </ToggleGroupItem>
                              </ToggleGroup>
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>
                    <FormField
                      control={form.control}
                      name={`legs.${index}.departureDate`}
                      defaultValue={
                        index === 0
                          ? new Date()
                          : form.getValues(`legs.${index - 1}.departureDate`)
                      }
                      render={({ field }) => (
                        <DatePicker
                          field={field}
                          form={form}
                          index={index}
                          minDate={
                            index > 0
                              ? form.getValues(`legs.${index - 1}.departureDate`)
                              : undefined
                          }
                        />
                      )}
                    />
                    <FormField
                      control={form.control}
                      name={`legs.${index}.estimatedTimeOfDeparture`}
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <TimeInput
                              field={field}
                              fieldName={`legs.${index}.estimatedTimeOfDeparture`}
                              form={form}
                              index={index}
                            />
                          </FormItem>
                        )
                      }}
                    />

                    <FormField
                      control={form.control}
                      name={`legs.${index}.pax`}
                      render={({ field }) => (
                        <PaxSelect
                          form={form}
                          fieldName={`legs.${index}.pax`}
                          field={field}
                        />
                      )}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div>
            <Button onClick={addLegFormHandler} type="button" variant="outline">
              <InlineSVG src={PlusIcon} className="min-w-4 pr-2" />
              <span className="!text-sm">Add Leg</span>
            </Button>
          </div>
          <FormField
            control={form.control}
            name="avinode"
            render={({ field }) => (
              <FormControl>
                <div className="relative flex items-center gap-2">
                  <Checkbox
                    {...field}
                    disabled={!data?.avinodeTokenExists}
                    value={String(field.value)}
                    checked={Boolean(field.value)}
                    onCheckedChange={field.onChange}
                    className="h-5 w-5 border-gray-200"
                  />
                  <FormLabel className="text-muted-foreground pl-1 text-xs font-medium">
                    {data?.avinodeTokenExists
                      ? "Prepopulate an Avinode trip"
                      : "Connect Avinode to enable the creation of Avinode trips."}
                  </FormLabel>
                </div>
              </FormControl>
            )}
          />
          <div className="flex w-1/2 items-end gap-2">
            <Button
              type="submit"
              disabled={isPending}
              className="bg-violet-700 hover:bg-violet-800 dark:text-slate-200 hover:dark:bg-slate-200 hover:dark:text-slate-900"
            >
              Search
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
