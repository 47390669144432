import { useQuery } from "@tanstack/react-query"
import { Link, useRouterState } from "@tanstack/react-router"

import { cn } from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

const OrganizationOffersSubheaderDocument = graphql(/* GraphQL */ `
  query OrganizationOffersSubheader {
    organizationOffers {
      id
      status
    }
  }
`)

interface OfferSubheaderTabPorps {
  status: string
  count: number
  isActive: boolean
}

const OfferSubheaderTab = ({ status, count, isActive }: OfferSubheaderTabPorps) => (
  <>
    <div className="flex items-center justify-center gap-2 px-4">
      <h3 className="text-center text-base">{status}</h3>
      {count > 0 && <span>({count})</span>}
    </div>
    <span
      className={cn(
        isActive ? "border-b-2 border-b-violet-700" : "border-b-2 border-b-gray-300",
      )}
    />
  </>
)

export const OffersSubheader = () => {
  const { currentOrg } = useOnboardedUserInfo()
  const category = useRouterState({
    select: (s) => s.location.pathname.split("/"),
  })

  const subcategory = category[category.length - 1]

  const { data, isLoading, isError } = useQuery({
    queryKey: ["organizationOffers"],
    queryFn: () => client.request(OrganizationOffersSubheaderDocument),
  })

  if (isLoading) return null
  if (isError) return <div>Error loading offers</div>

  const linkStyles = "flex flex-col gap-2 hover:cursor-pointer"

  const categorizeByStatus =
    data?.organizationOffers?.reduce(
      (acc, cur) => {
        acc[cur.status] = (acc[cur.status] || 0) + 1
        return acc
      },
      {} as Record<string, number>,
    ) || {}

  return (
    <div className="flex flex-col justify-center px-6 py-[11.45px] font-semibold">
      <div className="flex gap-2">
        <Link
          to="/$slug/offers"
          params={{ slug: currentOrg.slug }}
          className={linkStyles}
        >
          <OfferSubheaderTab
            status="Pending"
            count={categorizeByStatus.pending}
            isActive={category.includes("offers") && subcategory === "offers"}
          />
        </Link>

        <Link
          to="/$slug/offers/expired"
          params={{ slug: currentOrg.slug }}
          className={linkStyles}
        >
          <OfferSubheaderTab
            status="Expired"
            count={categorizeByStatus.expired}
            isActive={category.includes("offers") && category.includes("expired")}
          />
        </Link>
        <Link
          to="/$slug/offers/signed"
          params={{ slug: currentOrg.slug }}
          className={linkStyles}
        >
          <OfferSubheaderTab
            status="Signed"
            count={categorizeByStatus.signed}
            isActive={category.includes("offers") && category.includes("signed")}
          />
        </Link>
        <Link
          to="/$slug/offers/approved"
          params={{ slug: currentOrg.slug }}
          className={linkStyles}
        >
          <OfferSubheaderTab
            status="Approved"
            count={categorizeByStatus.approved}
            isActive={category.includes("offers") && category.includes("approved")}
          />
        </Link>
        <Link
          to="/$slug/offers/cancelled"
          params={{ slug: currentOrg.slug }}
          className={linkStyles}
        >
          <OfferSubheaderTab
            status="Cancelled"
            count={categorizeByStatus.cancelled}
            isActive={category.includes("cancelled") && category.includes("cancelled")}
          />
        </Link>
      </div>
    </div>
  )
}
