import { useRouterState } from "@tanstack/react-router"

import { OfferStatus } from "#components/offers/components/offer-status.js"
import type { StatusType } from "#components/offers/models/offer-types.js"

export type OffersHeaderProps = {
  status?: string
  expiresAt?: string
  title?: string
}

export const OffersHeader = ({ title, status, expiresAt }: OffersHeaderProps) => {
  const category = useRouterState({
    select: (s) => s.location.pathname.split("/"),
  })

  return (
    <h3 className="sticky left-0 top-0 z-[100] h-16 border-b border-gray-200 bg-gray-50 px-6 py-4 text-xl font-bold md:text-2xl dark:bg-gray-950">
      {category.includes("offer-client") ||
      category.includes("offer-edit") ||
      category.includes("preview-offer") ? (
        <div className="flex items-center gap-4">
          <p>Open offer</p>
          <OfferStatus status={status as StatusType} pendingTime={expiresAt} />
        </div>
      ) : (
        title
      )}
    </h3>
  )
}
