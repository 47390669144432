import { Route } from "@tanstack/react-router"

import { quotationsRoute } from "#pages/quotations/quotations-route.js"

import { CreateQuote } from "./page.js"

export const createQuoteRoute = new Route({
  getParentRoute: () => quotationsRoute,
  path: "create-quote/$trip",
  component: CreateQuote,
})
