import type { ControllerRenderProps, UseFormReturn } from "react-hook-form"

import { Button, FormControl, FormItem, FormLabel, FormMessage, Input } from "@fourel/ui"

import type { TripFormType } from "./quick-trip-request.js"

interface PaxSelectProps {
  field: ControllerRenderProps<TripFormType, `legs.${number}.pax`>
  fieldName: `legs.${number}.pax`
  form: UseFormReturn<TripFormType>
}

export const PaxSelect = ({ field, form, fieldName }: PaxSelectProps) => {
  return (
    <FormItem className="flex flex-col items-start gap-2 space-y-0">
      <FormLabel className="text-xs font-medium text-gray-500">Passengers</FormLabel>
      <div className="mt-0 flex w-full flex-col items-start justify-start">
        <FormControl>
          <div className="flex w-full items-center gap-1">
            <Button
              type="button"
              variant="outline"
              className="w-8 p-0 sm:w-10"
              onClick={() => {
                const value = parseInt(field.value, 10) || 1
                if (value >= 1) {
                  form.setValue(fieldName, `${value - 1}`)
                }
              }}
            >
              -
            </Button>
            <Input
              className="w-8 p-0 text-center text-xs font-medium hover:bg-slate-100 sm:w-10"
              type="text"
              autoFocus={false}
              maxLength={2}
              {...field}
              onChange={(event) => {
                const value = event.target.value.replace(/[^0-9]/g, "")
                form.setValue(fieldName, value)
              }}
            />
            <Button
              type="button"
              variant="outline"
              className="w-8 p-0 sm:w-10"
              onClick={() => {
                const value = parseInt(field.value, 10) || 0
                if (value < 50) {
                  form.setValue(fieldName, `${value + 1}`)
                }
              }}
            >
              +
            </Button>
          </div>
        </FormControl>
        <FormMessage className="mt-0 text-xs" />
      </div>
    </FormItem>
  )
}
