import { useQuery } from "@tanstack/react-query"
import { useParams } from "@tanstack/react-router"

import { Offer } from "#components/offers/components/offer.js"
import { graphql } from "#gql"
import { client } from "#graphql-client.js"

import { OfferSpinningLogo } from "../components/offer-spinning-logo.js"

const ClientOfferByStatusDocument = graphql(/* GraphQL */ `
  query ClientOfferByStatusCancelled($input: QueryClientOfferByStatusInput!) {
    clientOfferByStatus(input: $input) {
      id
      status
      expiresAt
      trip {
        id
        client {
          id
          name
        }
      }
      tripOptionsSelectedByClient {
        id
        createdAt
        quotes {
          createdAt
          expiresAt
          id
          status
          currency
          price
          notes
          quoteLegs {
            notes
            id
            sequenceNumber
            pax
            estimatedTimeOfDeparture
            estimatedTimeOfArrival
            arrivalDate
            departureDate
            localDepartureDateTime
            localArrivalDateTime
            aircraft {
              id
              pax
              registrationNumber
              yearOfBuild
              model
              images
            }
            departureAirport {
              id
              city
              name
              icaoCode
              iataCode
            }
            arrivalAirport {
              id
              city
              name
              icaoCode
              iataCode
            }
          }
        }
      }
    }
  }
`)

export const Cancelled = () => {
  const { client: clientId } = useParams({
    from: "/authenticated-route/onboarded-route/$slug/clients/$client/offers/cancelled",
  })
  const { data, isLoading } = useQuery({
    queryKey: ["clientOfferByStatusCancelled"],
    queryFn: () =>
      client.request(ClientOfferByStatusDocument, {
        input: { status: "cancelled", clientId },
      }),
  })

  if (isLoading) {
    return <OfferSpinningLogo />
  }

  const offers = data?.clientOfferByStatus

  return offers && offers.length > 0 ? (
    offers.map((offer) => {
      const quotes = offer.tripOptionsSelectedByClient.flatMap(
        (tripOption) => tripOption.quotes,
      )
      return (
        <Offer
          key={offer.id}
          quotes={quotes}
          status={offer.status}
          id={offer.id}
          expiresAt={offer.expiresAt}
          trip={offer.trip}
        />
      )
    })
  ) : (
    <div className="flex flex-col">
      <p className="py-3 text-center text-lg font-semibold">No Cancelled offers</p>
    </div>
  )
}
