import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Row } from "@tanstack/react-table"
import type { GraphQLError } from "graphql/error"
import MoreHorizontalIcon from "lucide-static/icons/more-horizontal.svg"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  ToastAction,
  useToast,
} from "@fourel/ui"

import type { MutationUpdateUserRoleInput } from "#gql/graphql.js"
import { graphql } from "#gql/index.js"
import { client } from "#graphql-client.js"
import { DeleteUser } from "#pages/settings/members/components/delete-user.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

import { memberSchema } from "../index.js"

interface DataTableRowActionsProps<TData> {
  row: Row<TData>
}

const UpdateUserRoleDocument = graphql(/* GraphQL */ `
  mutation UpdateUserRole($input: MutationUpdateUserRoleInput!) {
    updateUserRole(input: $input)
  }
`)

const organizationRoles = ["owner", "admin", "sales", "flight_support", "member"]

export function MembersRowAction<TData>({ row }: DataTableRowActionsProps<TData>) {
  const member = memberSchema.parse(row.original)
  const { currentOrg, me } = useOnboardedUserInfo()
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const setNewRole = useMutation({
    mutationFn: (input: MutationUpdateUserRoleInput) =>
      client.request(UpdateUserRoleDocument, { input }),
    onSuccess: async () => {
      void queryClient.invalidateQueries({ queryKey: ["OrganizationUsers"] })
      toast({
        title: "Success!",
        description: "User role has been updated.",
        action: <ToastAction altText="Try again">Close</ToastAction>,
      })
    },
    onError: (data) => {
      if (data instanceof Object && "request" in data && "response" in data) {
        // @ts-expect-error graphql-request doesn't export types, so we hack around it
        const errors = data.response?.errors as GraphQLError[] | undefined
        toast({
          variant: "destructive",
          title: "Uh oh!",
          description:
            (errors && errors[0].message) || "There was a problem with your request.",
          action: <ToastAction altText="Close">Close</ToastAction>,
        })
        return
      }
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const onChangeRole = (
    role: "admin" | "member" | "owner" | "sales" | "flight_support",
  ) => {
    setNewRole.mutate({
      organizationId: currentOrg.id,
      userId: member.id,
      name: role,
    })
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="p-2">
          <InlineSVG src={MoreHorizontalIcon} className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Edit Role</DropdownMenuSubTrigger>
          <DropdownMenuSubContent>
            <DropdownMenuRadioGroup value={member.role}>
              {organizationRoles.map((label) => (
                <DropdownMenuRadioItem
                  className="flex justify-between"
                  key={label}
                  value={label}
                  onClick={() => onChangeRole(label as "admin" | "member" | "owner")}
                >
                  {label
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuSubContent>
        </DropdownMenuSub>
        {member.id !== me.user.id ? (
          <>
            <DropdownMenuSeparator />
            <DropdownMenu>
              <DeleteUser userId={member.id} organizationId={currentOrg.id} />
            </DropdownMenu>
          </>
        ) : null}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
