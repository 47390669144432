import { useNavigate } from "@tanstack/react-router"
import CircleCheckIcon from "lucide-static/icons/check-circle.svg"
import FileIcon from "lucide-static/icons/file.svg"
import type { UseFormReturn } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import { Button } from "@fourel/ui"

import type { FlightFollowUpListFormType } from "../../flights-list.js"

export const BriefConfirmationStatus = ({
  flightId,
  form,
  fieldName,
  to,
}: {
  flightId?: string | null
  form: UseFormReturn<FlightFollowUpListFormType>
  fieldName:
    | `generalTableLegs.${number}.briefStatus`
    | `generalTableLegs.${number}.confirmationStatus`
  to: "/flight-brief" | "/flight-confirmation"
}) => {
  const navigate = useNavigate()
  const status = form.getValues(fieldName)

  const flightBriefConfirmationStatusHandler = () => {
    if (!flightId) {
      return
    }
    void navigate({
      to,
      search: { flightId },
    })
  }

  return (
    <Button
      className="flex cursor-pointer items-center gap-2 p-2"
      asChild
      variant="outline"
      onClick={flightBriefConfirmationStatusHandler}
    >
      <div className="flex w-max items-center gap-2 p-4">
        {status ? (
          <>
            <span>Sent</span>
            <InlineSVG src={CircleCheckIcon} className="size-4 text-green-500" />
          </>
        ) : (
          <>
            <span>Send</span>
            <InlineSVG src={FileIcon} className="size-4 text-slate-500" />
          </>
        )}
      </div>
    </Button>
  )
}
