import { create } from "zustand"

import type { TripParseMutation } from "#gql/graphql"
import type { ExtractTypeFromPath } from "#utils/query-type-extractor.js"

type ParsedTrip = ExtractTypeFromPath<TripParseMutation, "tripParse">

type ParsedTripStore = {
  clientId: string
  clientName: string
  requestedLegs: ParsedTrip["requestedLegs"]
  setParsedTrip: (parsedTrip: ParsedTrip) => void
  clearParsedTrip: () => void
}

export const useParsedTripStore = create<ParsedTripStore>((set) => ({
  clientId: "",
  clientName: "",
  requestedLegs: [],

  setParsedTrip: (parsedTrip) =>
    set({
      clientId: parsedTrip.clientId,
      clientName: parsedTrip.clientName,
      requestedLegs: parsedTrip.requestedLegs,
    }),

  clearParsedTrip: () =>
    set({
      clientId: "",
      clientName: "",
      requestedLegs: [],
    }),
}))
