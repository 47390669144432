import React from "react"

import { AIRequest } from "#components/ai-request.js"
import { Flights } from "#pages/dashboard/components/flights.js"
import { QuickTripRequest } from "#pages/dashboard/components/quick-trip-request.js"

export const Dashboard = () => {
  return (
    <div className="h-[calc(100vh-112px)] md:h-[calc(100vh-64px)]">
      <h3 className="sticky left-0 top-0 z-[100] h-16 border-b border-gray-200 bg-gray-50 px-6 py-4 text-xl font-bold md:text-2xl dark:bg-gray-950">
        Dashboard
      </h3>
      <div className="flex w-full items-start justify-between gap-2 p-4">
        <div className="w-3/4 rounded-sm border p-2">
          <QuickTripRequest />
        </div>
        <div className="sticky top-20 h-max w-1/4 rounded-sm border p-2">
          <AIRequest />
        </div>
      </div>
      <div className="px-4">
        <Flights />
      </div>
    </div>
  )
}
