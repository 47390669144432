import { useQuery } from "@tanstack/react-query"
import { Route } from "@tanstack/react-router"
import type { ColumnDef } from "@tanstack/react-table"
import { z } from "zod"

import { Avatar, AvatarFallback, AvatarImage, Button, Separator } from "@fourel/ui"

import { AddMember } from "#components/add-member.js"
import { graphql } from "#gql/index.js"
import { client } from "#graphql-client.js"
import { settingsRoute } from "#pages/settings/settings-route.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

import { SettingsCompanyInvites } from "./components/company-invites.js"
import { MembersRowAction } from "./components/members-row-action.js"
import { OrganizationUsersTable } from "./components/organization-users-table.js"

export const memberSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().email(),
  role: z.string(),
})
export type Member = z.infer<typeof memberSchema>

const OrganizationUsersDocument = graphql(/* GraphQL */ `
  query OrganizationUsers($input: QueryOrganizationUsersInput!) {
    organizationUsers(input: $input) {
      id
      name
      email
      role
    }
  }
`)

const columns: ColumnDef<Member>[] = [
  {
    id: "avatar",
    cell: ({ row }) => (
      <div className="flex items-center justify-center">
        <Avatar className="h-9 w-9">
          <AvatarImage src="src" alt="@fourel" />
          <AvatarFallback>{row.original.name.slice(0, 2).toUpperCase()}</AvatarFallback>
        </Avatar>
      </div>
    ),
  },
  {
    id: "name",
    cell: ({ row }) => (
      <div className="flex flex-col items-start justify-start">
        <span>
          {row.original.name.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
        </span>
        <span>{row.original.email}</span>
      </div>
    ),
  },
  {
    id: "role",
    cell: ({ row }) => (
      <div className="flex w-32 items-center justify-center">
        {row.original.role
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase())}
      </div>
    ),
  },
  {
    id: "actions",
    cell: ({ row }) => (
      <div className="self-center">
        <MembersRowAction row={row} />
      </div>
    ),
  },
]

const SettingsMembers = () => {
  const { currentOrg } = useOnboardedUserInfo()
  const { data } = useQuery({
    queryKey: ["OrganizationUsers"],
    queryFn: () =>
      client.request(OrganizationUsersDocument, { input: { id: currentOrg.id } }),
  })
  const db = z.array(memberSchema).parse(data?.organizationUsers ?? [])

  return (
    <div className="w-full pr-6">
      <div className="mb-6 flex flex-col">
        <span className="dark:text-foreground pb-1 text-lg font-semibold text-slate-900">
          Members
        </span>
        <span className="dark:text-muted-foreground pb-6 text-sm text-slate-600">
          Manage who has access to this workspace
        </span>
        <Separator />
      </div>
      <p className="dark:text-muted-foreground pb-12 text-sm text-slate-600">
        On the Free plan all members in a workspace are administrators. Upgrade to the
        Standard plan to add the ability to assign or remove administrator roles.
      </p>
      {currentOrg.isUserAdmin && (
        <Button variant="primary" className="mb-12 flex w-max self-start">
          <AddMember open />
        </Button>
      )}
      <OrganizationUsersTable data={db} columns={columns} />
      <SettingsCompanyInvites />
    </div>
  )
}

export const settingsMembersRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "/members",
  component: SettingsMembers,
})
