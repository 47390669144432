import { Route } from "@tanstack/react-router"

import { sluggedRoute } from "#router.js"

import { Integration } from "./page.js"

export const integrationRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/integration",
  component: Integration,
})
