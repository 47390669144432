import type { FlightFollowUpListFormType } from "./flights-list.js"

export const tablesDefaultValues: FlightFollowUpListFormType = {
  generalTableLegs: [
    {
      briefStatus: false,
      confirmationStatus: false,
      status: "not_selected",
      paxBrief: "no",
      departureTerminal: "not_selected",
      arrivalTerminal: "not_selected",
    },
  ],
  preparationTableLegs: [
    {
      depArrConfirmation: "not_selected",
      pax: "requested",
      flightCatering: "not_selected",
      transfer: "not_selected",
      pprForCar: "not_selected",
    },
  ],
  preFlightTableLegs: [
    {
      acPosition: "not_departed",
      slots: "not_granted",
      crew: "not_arrived",
      fuel: "no",
      catering: "not_loaded",
      acStatus: "not_ready",
      pax: "not_arrived",
    },
  ],
  contractPaymentTableLegs: [
    {
      operatorContract: "signed",
      clientContract: "signed",
      operatorPayment: "not_paid",
      clientPayment: "not_paid",
      leg: "not_selected",
    },
  ],
  flightCrew: [
    [
      { id: "", name: "", phone: "", position: "pilot_in_command" },
      { id: "", name: "", phone: "", position: "first_officer" },
      { id: "", name: "", phone: "", position: "flight_attendant" },
    ],
  ],
  flightHost: [
    {
      departureHost: {
        id: "",
        name: "",
        phone: "",
      },
      arrivalHost: {
        id: "",
        name: "",
        phone: "",
      },
    },
  ],
  flightAssistant: [
    {
      brokerAssistant: [],
      flightSupports: [],
    },
  ],
  flightDateTimePax: [
    {
      departureDate: "",
      estimatedTimeOfDeparture: "",
      pax: "",
    },
  ],
  flightRegNumber: [
    {
      registrationNumber: "",
    },
  ],
}
