import { create } from "zustand"

import type { AircraftToLegDataType } from "./components/duplicate-aircraft.js"

type AircraftStore = {
  aircraftToLegData: AircraftToLegDataType[] | []
  aircraftSearchParam: string
  overwriteAircraftToLegData: (newData: AircraftToLegDataType) => void
  updateAircraftToLegField: (newData: AircraftToLegDataType) => void
  updateAircraftSearchParam: (newSearchParam: string) => void
}

export const useAircraftStore = create<AircraftStore>((set) => ({
  aircraftToLegData: [],
  aircraftSearchParam: "",
  overwriteAircraftToLegData: (newData: AircraftToLegDataType) =>
    set((state) => {
      const updatedAircraftData = state.aircraftToLegData.map((item) => ({
        ...item,
        aircrafts: newData.aircrafts,
      }))

      return { aircraftToLegData: updatedAircraftData }
    }),
  updateAircraftToLegField: (newData: AircraftToLegDataType) =>
    set((state) => {
      const existingIndex = state.aircraftToLegData.findIndex(
        (item) => item.storeLegIndex === newData.storeLegIndex,
      )

      if (existingIndex !== -1) {
        const updatedAircraftData = state.aircraftToLegData.map((item, index) =>
          index === existingIndex ? { ...newData } : item,
        )
        return { aircraftToLegData: updatedAircraftData }
      }

      return { aircraftToLegData: [...state.aircraftToLegData, newData] }
    }),

  updateAircraftSearchParam: (newSearchParam: string) =>
    set({ aircraftSearchParam: newSearchParam }),
}))
