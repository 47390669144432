import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { settingsRoute } from "#pages/settings/settings-route.js"

export const settingsBrokerRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "broker",
  validateSearch: (search: Record<string, unknown>): { offerId?: string } => {
    return {
      offerId: search.offerId as string,
    }
  },
  component: lazyRouteComponent(() => import("./page.js"), "BrokerSettings"),
})
