export const QueryKeys = {
  Operators: {
    Get: "operatorsKey",
    GetById: "operatorByIdKey",
  },
  Clients: {
    GetByOrg: "organizationClientsKey",
  },
  Aircraft: {
    GetByRegNumber: "aircraftByRegNumberKey",
    GetByOperatorId: "arcraftsByOperatorIdKey",
    GetByModelAndRegNumber: "aircraftsByModelAndRegNumberKey",
  },
  Airport: {
    GetByName: "airportByNameKey",
  },
  Trips: {
    GetByOrganizationId: "tripsByOrganizationIdKey",
    Delete: "tripDeleteKey",
    Archive: "tripUpdateIsArchivedStatusKey",
    Get: "tripKey",
    SetDeleted: "tripSetDeletedKey",
  },
  Quotes: {
    GetTripsByOrganizationIdQuotes: "TripsByOrganizationIdQuotes",
  },
  SettingsPreferences: {
    GetByOrgId: "preferenceByCurrentOrganizationIdKey",
    GetByOrgIds: "preferencesByOrganizationIdsKey",
  },
  SettingsProfile: {
    GetByUserIdAndOrgId: "profileByUserIdAndOrgIdKey",
  },
}
