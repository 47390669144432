import { Route } from "@tanstack/react-router"

import { Dashboard } from "#pages/dashboard/page.js"
import { sluggedRoute } from "#router.js"

export const dashboardRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/dashboard",
  component: Dashboard,
})
