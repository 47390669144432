import type { DynamicOfferDataQuery } from "#gql/graphql.js"
import type { ExtractTypeFromPath } from "#utils/query-type-extractor.js"

type CombinedTrips = ExtractTypeFromPath<
  DynamicOfferDataQuery,
  ["dynamicOffer", "tripOptionsOffered"]
>

export const getTimeDifference = ({
  startDateTime,
  endDateTime,
  startTime,
  endTime,
}: {
  startDateTime: string
  endDateTime: string
  startTime: string
  endTime: string
}) => {
  const startDate = startDateTime.split("T")[0]
  const endDate = endDateTime.split("T")[0]

  const myStartDateTime = `${startDate}T${startTime}.000Z`
  const myEndDateTime = `${endDate}T${endTime}.000Z`

  const startDateTimeInMs = new Date(myStartDateTime).getTime()
  const endDateTimeInMs = new Date(myEndDateTime).getTime()

  const differenceInMilliseconds = endDateTimeInMs - startDateTimeInMs
  const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000)

  const hours = Math.floor(differenceInMinutes / 60)
    .toString()
    .padStart(2, "0")
  const minutes = (differenceInMinutes % 60).toString().padStart(2, "0")

  return `${hours}:${minutes}`
}

export const tripOptionsQuoteLegsToMap = (tripOption: CombinedTrips) =>
  tripOption.quotes.flatMap((quote) => quote.quoteLegs)

export const aircraftByLegs = (tripOption: CombinedTrips) => {
  const airplaneMap = new Map()
  tripOptionsQuoteLegsToMap(tripOption).forEach((leg) => {
    const airplane = leg.aircraft
    if (!airplaneMap.has(airplane.id)) {
      airplaneMap.set(airplane.id, {
        airplane,
        legs: [],
      })
    }
    airplaneMap.get(airplane.id).legs.push(leg)
  })
  return Array.from(airplaneMap.values())
}
