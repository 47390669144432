import { useQuery } from "@tanstack/react-query"
import debounce from "debounce"
import CheckIcon from "lucide-static/icons/check.svg"
import ChevronDownIcon from "lucide-static/icons/chevron-down.svg"
import { useState } from "react"
import type { ControllerRenderProps } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContentCombobox,
  PopoverTrigger,
} from "@fourel/ui"

import { QueryKeys } from "#constants/query-keys.js"
import { graphql } from "#gql"
import type { AirportByNameTripQuery } from "#gql/graphql.js"
import { client } from "#graphql-client"
import type { ExtractTypeFromPath } from "#utils/query-type-extractor.js"

import type { TripFormType } from "./quick-trip-request.js"

type AirportType = ExtractTypeFromPath<AirportByNameTripQuery, "airportByName">

const AirportByNameDocument = graphql(/* GraphQL */ `
  query AirportByNameTrip($input: QueryAirportByNameInput!) {
    airportByName(input: $input) {
      id
      name
      icaoCode
      iataCode
      status
    }
  }
`)

interface AirportSelectProps {
  field:
    | ControllerRenderProps<TripFormType, `legs.${number}.departureAirport`>
    | ControllerRenderProps<TripFormType, `legs.${number}.arrivalAirport`>
  fieldName: `legs.${number}.departureAirport` | `legs.${number}.arrivalAirport`
  placeholder: string
  airports: AirportType[]
  setValue: (
    field: `legs.${number}.departureAirport` | `legs.${number}.arrivalAirport`,
    value: { id: string; label: string; code: string },
  ) => void
}

export const AirportSelect = ({
  field,
  fieldName,
  placeholder,
  airports,
  setValue,
}: AirportSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [airportSearchParam, setAirportSearchParam] = useState<string>("")

  const { data } = useQuery({
    queryKey: [QueryKeys.Airport.GetByName, { airportSearchParam }],
    queryFn: () =>
      client.request(AirportByNameDocument, {
        input: { airportName: airportSearchParam },
      }),
    enabled: !!airportSearchParam,
  })

  const handleInputChange = debounce((value) => {
    if (value.length > 2) {
      setAirportSearchParam(value)
    } else {
      setAirportSearchParam("")
    }
  }, 500)

  const airportSelectHandler = (item: AirportType) => {
    setValue(fieldName, {
      id: item.id,
      label: item.name,
      code: item.icaoCode || item.iataCode!,
    })
    setIsOpen(false)
  }
  return (
    <FormItem className="flex w-full flex-col items-start gap-2 space-y-0">
      <div className="flex items-center gap-3">
        <FormLabel className="text-left text-xs font-medium text-gray-500">
          {placeholder}
        </FormLabel>
        <div className="flex items-center gap-2">
          {airports.length > 1 &&
            airports.map((it, i, self) => {
              const isLastItem = i === self.length - 1
              return (
                <button
                  type="button"
                  onClick={() => airportSelectHandler(it)}
                  className={cn(
                    "border-r-2 border-black/30 pr-2 text-xs font-medium text-gray-600",
                    isLastItem && "border-0",
                    it.id === field.value.id && "text-red-500",
                  )}
                  key={it.id}
                >
                  {it.icaoCode || it.iataCode}
                </button>
              )
            })}
        </div>
      </div>

      <div className="flex w-full flex-col">
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                variant="outline"
                role="combobox"
                className={cn(
                  "w-full cursor-pointer justify-between text-xs font-medium text-slate-900",
                  !field.value.label && "text-muted-foreground",
                )}
              >
                {field.value.label ? (
                  <div className="flex w-full items-center gap-0.5">
                    <span className="font-bold">{field.value.code}</span>-
                    <span>{field.value.label.replace(/Airport/i, "").trim()}</span>
                  </div>
                ) : (
                  <p>Select Airport</p>
                )}
                <InlineSVG
                  src={ChevronDownIcon}
                  className="ml-2 h-4 w-4 shrink-0 opacity-50"
                />
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContentCombobox className="w-64 p-0">
            <Command shouldFilter={false}>
              <CommandInput
                placeholder="Search Airport"
                className="placeholder:text-xs"
                onValueChange={handleInputChange}
              />
              <CommandEmpty>No airport found.</CommandEmpty>
              <CommandList className="max-h-60">
                {data?.airportByName.map(
                  (item) =>
                    item.status === "active" && (
                      <CommandItem
                        value={item.name}
                        key={item.id}
                        onSelect={() => airportSelectHandler(item as AirportType)}
                        className="cursor-pointer text-xs"
                      >
                        <InlineSVG
                          src={CheckIcon}
                          className={cn(
                            "mr-2 size-4",
                            field.value && item.name === field.value.label
                              ? "opacity-100"
                              : "opacity-0",
                          )}
                        />
                        {item.name} - {item.icaoCode || item.iataCode}
                      </CommandItem>
                    ),
                )}
              </CommandList>
            </Command>
          </PopoverContentCombobox>
        </Popover>
        <FormMessage className="text-xs" />
      </div>
    </FormItem>
  )
}
