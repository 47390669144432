import ChevronsDownIcon from "lucide-static/icons/chevrons-down.svg"
import ChevronsUpIcon from "lucide-static/icons/chevrons-up.svg"
import React from "react"
import type { UseFormReturn } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import { Button, cn, FormControl, FormField, FormItem, FormMessage } from "@fourel/ui"

import type { FlightsOrganizationByDateQuery } from "#gql/graphql.js"
import { useAccordionStore, useTableStateStore } from "#pages/flights/flights-store.js"
import type { SelectOptionType } from "#pages/flights/models/flight-follow-up-types.js"

import { AccordionContentGeneral } from "../accordion/accordion-content-general.js"
import type { FlightFollowUpListFormType } from "../flights-list.js"
import { convertDateFormat, convertTimeFormat } from "../helpers/index.js"
import { BriefConfirmationStatus } from "./components/brief-confirmation-status.js"
import { EditFlightNumber } from "./components/edit-flight-number.js"
import { EditFlight } from "./components/edit-flight.js"
import { FlightsListSelect } from "./components/select.js"
import { TableExpandHeader } from "./components/table-expand-header.js"

const tableSubheaderData = [
  { title: "" },
  { title: "A/C Reg. number" },
  { title: "Edit Date & Pax" },
  { title: "Flight confirmation" },
  { title: "Flight brief" },
  { title: "DEP-ARR" },
  { title: "Date" },
  { title: "DEP time" },
  { title: "ARR time" },
  { title: "Pax" },
  { title: "Status" },
  { title: "Pax brief" },
  { title: "VIP TER DEP" },
  { title: "VIP TER ARR" },
]

const statusOptions: SelectOptionType[] = [
  { field: "Not selected", value: "not_selected", default: true },
  { field: "Confirmed", value: "confirmed", default: false },
  { field: "Awaiting reply", value: "awaiting_reply", default: false },
  { field: "Cancelled", value: "cancelled", default: false },
]

const paxBriefOptions: SelectOptionType[] = [
  { field: "No", value: "no", default: true },
  { field: "Yes", value: "yes", default: false },
]

const vipTerOptions: SelectOptionType[] = [
  { field: "Not selected", value: "not_selected", default: true },
  { field: "Vip", value: "vip", default: false },
  {
    field: "General Aviation Terminal",
    value: "general_aviation_terminal",
    default: false,
  },
  { field: "Main", value: "main", default: false },
]

export const TableGeneral = ({
  flightData,
  form,
}: {
  flightData: FlightsOrganizationByDateQuery["flightsOrganizationByDate"]
  form: UseFormReturn<FlightFollowUpListFormType>
}) => {
  const { toggleAccordion, getAccordionState } = useAccordionStore()
  const { isGeneralTableExpanded } = useTableStateStore()

  form.watch(`flightDateTimePax`)
  form.watch(`flightRegNumber`)

  return (
    <div>
      <TableExpandHeader tableName="General" />

      <div className={`overflow-hidden ${isGeneralTableExpanded ? "w-full" : "w-auto"}`}>
        <table className={cn(!isGeneralTableExpanded && "w-52")}>
          <thead className="h-[35px]">
            <tr className="text-muted-foreground border-r text-xs">
              {isGeneralTableExpanded
                ? tableSubheaderData.map((item, index) => (
                    <th key={index} className="font-medium">
                      {item.title}
                    </th>
                  ))
                : null}
            </tr>
          </thead>

          <tbody>
            {flightData.length &&
              flightData.map((flight, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={cn(
                      (index + 1) % 2 === 0 && "bg-blue-300/10",
                      "h-[60px] border text-center text-sm font-semibold",
                    )}
                  >
                    <td className="p-2 pl-4 text-center text-sm font-semibold">
                      <Button
                        type="button"
                        variant="ghost"
                        onClick={() => toggleAccordion(flight.id)}
                      >
                        <InlineSVG
                          src={
                            getAccordionState(flight.id)?.isExpanded
                              ? ChevronsUpIcon
                              : ChevronsDownIcon
                          }
                          className="size-4"
                        />
                      </Button>
                    </td>

                    {!isGeneralTableExpanded && (
                      <td className="min-w-28 p-2 text-center text-sm font-semibold">
                        {form.getValues(`flightRegNumber.${index}.registrationNumber`) ||
                          "No Reg. number"}
                      </td>
                    )}
                    {isGeneralTableExpanded && (
                      <>
                        <td className="min-w-20 p-2 text-center text-sm font-semibold">
                          <EditFlightNumber
                            flightId={flight.id}
                            flightIndex={index}
                            form={form}
                          />
                        </td>
                        <td className="min-w-20 p-2 text-center text-sm font-semibold">
                          <EditFlight
                            flightId={flight.id}
                            flightIndex={index}
                            form={form}
                          />
                        </td>
                        <td className="min-w-20 p-2 text-center text-sm font-semibold">
                          <BriefConfirmationStatus
                            flightId={flight.id}
                            form={form}
                            fieldName={`generalTableLegs.${index}.confirmationStatus`}
                            to="/flight-confirmation"
                          />
                        </td>
                        <td className="min-w-20 p-2 text-center text-sm font-semibold">
                          <BriefConfirmationStatus
                            flightId={flight.id}
                            form={form}
                            fieldName={`generalTableLegs.${index}.briefStatus`}
                            to="/flight-brief"
                          />
                        </td>

                        <td className="min-w-28 p-2">
                          {flight.quoteLeg.departureAirport.iataCode ||
                            flight.quoteLeg.departureAirport.icaoCode}
                          -
                          {flight.quoteLeg.arrivalAirport.iataCode ||
                            flight.quoteLeg.arrivalAirport.icaoCode}
                        </td>
                        <td className="min-w-28 p-2">
                          <FormField
                            control={form.control}
                            name={`flightDateTimePax.${index}.departureDate`}
                            render={({ field }) => (
                              <FormItem>
                                <FormControl>
                                  <span>
                                    {field.value && convertDateFormat(field.value)}
                                  </span>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </td>
                        <td className="min-w-28 p-2">
                          <FormField
                            control={form.control}
                            name={`flightDateTimePax.${index}.estimatedTimeOfDeparture`}
                            render={({ field }) => (
                              <FormItem>
                                <FormControl>
                                  <span>{convertTimeFormat(`${field.value}:00`)}</span>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </td>
                        <td className="min-w-28 p-2">
                          {convertTimeFormat(flight.quoteLeg.estimatedTimeOfArrival)}
                        </td>
                        <td className="min-w-28 p-2">
                          <FormField
                            control={form.control}
                            name={`flightDateTimePax.${index}.pax`}
                            render={({ field }) => (
                              <FormItem className="relative w-32 space-y-0 p-2">
                                <FormControl>
                                  <span>{field.value}</span>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </td>
                        <td>
                          <FormField
                            control={form.control}
                            name={`generalTableLegs.${index}.status`}
                            render={() => (
                              <FormItem className="relative w-32 space-y-0 p-2">
                                <FormControl>
                                  <FlightsListSelect
                                    form={form}
                                    options={statusOptions}
                                    fieldName={`generalTableLegs.${index}.status`}
                                    flightId={flight.id}
                                    flightDocumentsId={flight.flightDocuments?.id}
                                    flightPreparationId={flight.flightPreparation?.id}
                                    preFlightId={flight.preFlight?.id}
                                    flightCateringId={
                                      flight.flightPreparation?.flightCatering[0]?.id
                                    }
                                    index={index}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </td>
                        <td>
                          <FormField
                            control={form.control}
                            name={`generalTableLegs.${index}.paxBrief`}
                            render={() => (
                              <FormItem className="relative w-32 space-y-0 p-2">
                                <FormControl>
                                  <FlightsListSelect
                                    form={form}
                                    options={paxBriefOptions}
                                    fieldName={`generalTableLegs.${index}.paxBrief`}
                                    flightId={flight.id}
                                    flightDocumentsId={flight.flightDocuments?.id}
                                    flightPreparationId={flight.flightPreparation?.id}
                                    preFlightId={flight.preFlight?.id}
                                    flightCateringId={
                                      flight.flightPreparation?.flightCatering[0]?.id
                                    }
                                    index={index}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </td>
                        <td>
                          <FormField
                            control={form.control}
                            name={`generalTableLegs.${index}.departureTerminal`}
                            render={() => (
                              <FormItem className="relative w-32 space-y-0 p-2">
                                <FormControl>
                                  <FlightsListSelect
                                    form={form}
                                    options={vipTerOptions}
                                    fieldName={`generalTableLegs.${index}.departureTerminal`}
                                    flightId={flight.id}
                                    flightDocumentsId={flight.flightDocuments?.id}
                                    flightPreparationId={flight.flightPreparation?.id}
                                    preFlightId={flight.preFlight?.id}
                                    flightCateringId={
                                      flight.flightPreparation?.flightCatering[0]?.id
                                    }
                                    index={index}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </td>
                        <td>
                          <FormField
                            control={form.control}
                            name={`generalTableLegs.${index}.arrivalTerminal`}
                            render={() => (
                              <FormItem className="relative w-32 space-y-0 p-2">
                                <FormControl>
                                  <FlightsListSelect
                                    form={form}
                                    options={vipTerOptions}
                                    fieldName={`generalTableLegs.${index}.arrivalTerminal`}
                                    flightId={flight.id}
                                    flightDocumentsId={flight.flightDocuments?.id}
                                    flightPreparationId={flight.flightPreparation?.id}
                                    preFlightId={flight.preFlight?.id}
                                    flightCateringId={
                                      flight.flightPreparation?.flightCatering[0]?.id
                                    }
                                    index={index}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                  {getAccordionState(flight.id)?.isExpanded && (
                    <tr>
                      <td
                        colSpan={tableSubheaderData.length}
                        className={cn(
                          (index + 1) % 2 === 0 && "bg-blue-300/10",
                          "h-[200px] py-4 pl-8 align-top",
                          index + 1 === flightData.length && "border-b",
                        )}
                      >
                        <AccordionContentGeneral
                          form={form}
                          flightIndex={index}
                          flightId={flight.id!}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
