import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { previewOfferRoute } from "#pages/offers/preview-offer/index.js"

const allowedOfferStatuses = [
  "pending",
  "approved",
  "signed",
  "cancelled",
  "expired",
] as const

export const offerGeneralRoute = new Route({
  getParentRoute: () => previewOfferRoute,
  path: "/",
  validateSearch: (
    search: Record<string, unknown>,
  ): {
    offerStatus?: "pending" | "approved" | "signed" | "cancelled" | "expired"
    preview?: boolean
    bookManually?: boolean
  } => {
    const offerStatus = allowedOfferStatuses.includes(search.offerStatus as string)
      ? (search.offerStatus as
          | "pending"
          | "approved"
          | "signed"
          | "cancelled"
          | "expired")
      : "pending"

    return {
      offerStatus,
      preview: search.preview === true,
      bookManually: search.bookManually === true,
    }
  },
  component: lazyRouteComponent(() => import("./page.js"), "OfferGeneral"),
})
