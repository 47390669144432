import CalendarIcon from "lucide-static/icons/calendar.svg"
import { useState } from "react"
import type { ControllerRenderProps } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Calendar,
  cn,
  format,
  FormControl,
  FormItem,
  FormMessage,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@fourel/ui"

import type { QuoteFormType } from "../page.js"

interface DatePickerProps {
  minDate?: Date | null | string
  field:
    | ControllerRenderProps<QuoteFormType, `legs.${number}.departureDate`>
    | ControllerRenderProps<QuoteFormType, `legs.${number}.arrivalDate`>
  placeholder: string
  fieldName: `legs.${number}.departureDate` | `legs.${number}.arrivalDate`
  setValue: (
    field: `legs.${number}.departureDate` | `legs.${number}.arrivalDate`,
    value: Date,
  ) => void
  revalidate: (
    name: `legs.${number}.departureDate` | `legs.${number}.arrivalDate`,
  ) => void
}

export function DatePicker({
  field,
  placeholder,
  fieldName,
  setValue,
  revalidate,
  minDate,
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false)
  const today = new Date()
  today.setHours(12, 0, 0, 0)

  const handleDateSelect = (date: Date | undefined) => {
    if (!date) {
      return
    }
    const selectedDate = new Date(date)
    selectedDate.setHours(12, 0, 0, 0)
    setValue(fieldName, selectedDate)
    revalidate(fieldName)
    setIsOpen(false)
  }

  const isDateDisabled = (date: Date): boolean => {
    const todayStart = new Date().setHours(0, 0, 0, 0)
    const dateTime = date.getTime()

    return !!(
      dateTime < todayStart ||
      (minDate && dateTime < new Date(minDate).setHours(0, 0, 0, 0))
    )
  }

  return (
    <FormItem className="w-full">
      <div className="flex items-center justify-between gap-2">
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                variant="outline"
                className={cn(
                  "w-full pl-3 text-left text-xs font-medium text-slate-900",
                  !field.value && "text-muted-foreground",
                )}
              >
                {field.value ? format(field.value, "PPP") : <span>{placeholder}</span>}
                <InlineSVG src={CalendarIcon} className="ml-auto h-4 w-4 opacity-50" />
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={field.value ? new Date(field.value) : undefined}
              onSelect={handleDateSelect}
              initialFocus
              disabled={isDateDisabled}
            />
          </PopoverContent>
        </Popover>
      </div>
      <FormMessage className="text-xs" />
    </FormItem>
  )
}
