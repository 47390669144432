import { useQuery } from "@tanstack/react-query"
import { Link, useNavigate } from "@tanstack/react-router"
import CheckIcon from "lucide-static/icons/check.svg"
import LogOutIcon from "lucide-static/icons/log-out.svg"
import UsersIcon from "lucide-static/icons/users.svg"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@fourel/ui"

import { AddMember } from "#components/add-member.js"
import { QueryKeys } from "#constants/query-keys.js"
import { FeatureGate } from "#feature-gate/feature-gate.js"
import { getFragmentData, graphql } from "#gql"
import { client } from "#graphql-client.js"
import { renderOrganizationAvatarByOrgId } from "#pages/lobby/utils/organization-avatar-by-orgId.js"
import {
  FetchUserInfo_OrganizationFragmentDocument,
  useOnboardedUserInfo,
} from "#store/user-info.js"
import { signOut } from "#utils/auth.js"

import { AddCompanyDialog } from "./add-company-dialog.js"

const PreferencesByOrganizationIdsDocument = graphql(/* GraphQL */ `
  query PreferencesByOrganizationIds($input: QueryPreferencesByOrganizationIdsInput!) {
    preferencesByOrganizationIds(input: $input) {
      organizationAvatar
      organizationOfferLogo
      organizationId
    }
  }
`)

export function MobileCompanyDropdownHeader() {
  const {
    currentOrg: { id, name, isUserAdmin },
    me: { user },
    selectOrganization,
  } = useOnboardedUserInfo()
  const navigate = useNavigate()

  const organizations = getFragmentData(
    FetchUserInfo_OrganizationFragmentDocument,
    user.organizations,
  )

  const orgIds = organizations.map((org) => org.id)

  const { data: preferencesData } = useQuery({
    queryKey: [QueryKeys.SettingsPreferences.GetByOrgIds],
    queryFn: () =>
      client.request(PreferencesByOrganizationIdsDocument, {
        input: { organizationIds: orgIds },
      }),
    enabled: orgIds.length > 0,
  })

  const preferences = preferencesData?.preferencesByOrganizationIds ?? []

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="w-full">
          <Button
            variant="ghost"
            className={cn(
              "flex h-fit w-fit p-0 focus-visible:ring-transparent focus-visible:ring-offset-0",
            )}
          >
            {renderOrganizationAvatarByOrgId({
              organizationId: id,
              organizationName: name,
              preferences,
              customStyle: "h-8 w-8 rounded-md",
            })}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[252px]">
          <span className="ml-2 select-none pb-[10px] text-xs/[20px] text-slate-500">
            {user.email}
          </span>
          {organizations.map((orgData) => {
            return (
              <DropdownMenuItem
                key={orgData.id}
                className="cursor-pointer justify-between text-xs/[20px]"
                onClick={() => {
                  selectOrganization(orgData.slug)
                  void navigate({
                    to: "/$slug/clients",
                    params: {
                      slug: orgData.slug,
                    },
                  })
                }}
              >
                <div className="flex items-center gap-2">
                  {renderOrganizationAvatarByOrgId({
                    organizationId: orgData.id,
                    organizationName: orgData.name,
                    preferences,
                  })}
                  <span>{orgData.name}</span>
                </div>
                {id === orgData.id && (
                  <InlineSVG src={CheckIcon} className="ml-2 h-4 w-4 text-slate-500" />
                )}
              </DropdownMenuItem>
            )
          })}

          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <FeatureGate name="SettingsNotificationsRoute">
              <DropdownMenuItem className="cursor-pointer">
                <Link from="$slug" to="./settings/notifications" className="w-full">
                  <span>Workspace settings</span>
                </Link>
              </DropdownMenuItem>
            </FeatureGate>
            <FeatureGate name="SettingsBillingRoute">
              <DropdownMenuItem className="cursor-pointer">
                <Link from="/$slug" to="./settings/billing" className="w-full">
                  <span>Billing</span>
                </Link>
              </DropdownMenuItem>
            </FeatureGate>
            {isUserAdmin && (
              <DropdownMenu>
                <AddCompanyDialog />
              </DropdownMenu>
            )}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem className="cursor-pointer">
            <InlineSVG src={UsersIcon} className="mr-2 h-4 w-4" />
            <Link from="$slug" to="./settings/members" className="w-full">
              <span>Members</span>
            </Link>
          </DropdownMenuItem>
          {isUserAdmin && (
            <DropdownMenuGroup>
              <DropdownMenu>
                <AddMember asMenu open />
              </DropdownMenu>
            </DropdownMenuGroup>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={signOut} className="cursor-pointer">
            <InlineSVG src={LogOutIcon} className="mr-2 h-4 w-4" />
            <span>Log out</span>
            <DropdownMenuShortcut>⌘⇧B</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
