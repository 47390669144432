import CalendarIcon from "lucide-static/icons/calendar.svg"
import { useState } from "react"
import type { ControllerRenderProps, UseFormReturn } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Calendar,
  cn,
  format,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@fourel/ui"

import type { TripFormType } from "./quick-trip-request.js"

interface DatePickerProps {
  field: ControllerRenderProps<TripFormType, `legs.${number}.departureDate`>
  minDate?: Date | null | string
  form: UseFormReturn<TripFormType>
  index: number
}

export const DatePicker = ({ field, minDate, form, index }: DatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const handleDateSelect = (date: Date | undefined) => {
    const clearDate = new Date(date || new Date())
    clearDate.setHours(0)
    const utc = new Date(
      Date.UTC(clearDate.getFullYear(), clearDate.getMonth(), clearDate.getDate()),
    )
    field.onChange(utc)
    void form.trigger(`legs.${index}.estimatedTimeOfDeparture`)
    setIsOpen(false)
  }

  const isDateDisabled = (date: Date): boolean => {
    return !!(date < today || (minDate && date < minDate))
  }

  return (
    <FormItem className="flex w-36 flex-col items-start gap-2 space-y-0">
      <FormLabel className="text-xs font-medium text-gray-500">Date</FormLabel>
      <div className="flex w-full flex-col">
        <div className="space-y-1">
          <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  className={cn(
                    "w-full pl-3 text-left text-xs font-medium text-slate-900",
                    !field.value && "text-muted-foreground",
                  )}
                >
                  {field.value ? format(field.value, "PPP") : <span>Select Date</span>}
                  <InlineSVG src={CalendarIcon} className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value ? new Date(field.value) : undefined}
                onSelect={handleDateSelect}
                disabled={isDateDisabled}
              />
            </PopoverContent>
          </Popover>
        </div>
        <FormMessage className="text-xs" />
      </div>
    </FormItem>
  )
}
