import { useRouterState } from "@tanstack/react-router"

export const TripRequestsHeader = () => {
  const category = useRouterState({
    select: (s) => s.location.pathname.split("/"),
  })

  const subcategory = category[category.length - 1]
  const headerNames: Record<string, string> = {
    "trip-requests": "Active",
    add: "Create Trip",
  }

  const headerName = headerNames[subcategory] || subcategory
  const formattedHeaderName = `${headerName.slice(0, 1).toUpperCase()}${headerName.slice(1)}`

  return (
    <div className="flex h-16 w-full items-center justify-between gap-2 border-b border-gray-200 bg-gray-50 p-4 px-6 dark:bg-gray-950">
      <h1 className="text-xl font-bold md:text-2xl">
        Trip request \ {formattedHeaderName}
      </h1>
    </div>
  )
}
