import { Route } from "@tanstack/react-router"

import { clientsRoute } from "#pages/clients/index.js"

export const Flights = () => {
  return <div>Calendar</div>
}

export const clientsFlightsRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/flights",
  component: Flights,
})
