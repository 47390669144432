import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { offersRoute } from "#pages/offers/offers-route.js"

export const createOfferRoute = new Route({
  getParentRoute: () => offersRoute,
  path: "/create-offer",
  validateSearch: (search: Record<string, unknown>): { tripId: string } => {
    if ("tripId" in search) {
      return {
        tripId: String(search.tripId),
      }
    }
    throw Error("Invalid search parameters")
  },
  component: lazyRouteComponent(() => import("./page.js"), "CreateOffer"),
})
