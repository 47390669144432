interface Airport {
  __typename?: "Airport" | undefined
  city?: string
  iataCode?: string | null | undefined
  icaoCode?: string | null | undefined
  id?: string
  name?: string
}

export const formatAirportCode = (airport: Airport) =>
  airport.icaoCode || airport.iataCode
