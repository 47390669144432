import { Route } from "@tanstack/react-router"

import { sluggedRoute } from "#router.js"

import { Clients } from "./page.js"

export const clientsRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/clients",
  component: Clients,
})
