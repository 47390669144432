import { Link, useRouterState } from "@tanstack/react-router"
import BuildingIcon from "lucide-static/icons/building.svg"
import ChevronRightIcon from "lucide-static/icons/chevron-right.svg"
import UserCogIcon from "lucide-static/icons/user-cog.svg"
import InlineSVG from "react-inlinesvg"

import {
  cn,
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  DrawerClose,
} from "@fourel/ui"

import {
  commandInputStyles,
  profileItems,
  workspaceItems,
} from "#pages/lobby/components/settings-menu-tabs.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

export const SettingsMobileMenuTabs = ({ open }: { open: boolean }) => {
  const { currentOrg } = useOnboardedUserInfo()

  const menuCategoryRegex = /\/settings\/([^/]+)/
  const menuCategoryMatch = useRouterState({
    select: (s) => s.location.pathname.match(menuCategoryRegex),
  })
  const menuCategoryName = menuCategoryMatch ? menuCategoryMatch[1] : "no match"

  return (
    <div className="flex flex-auto overflow-hidden pl-2 text-sm">
      <Command className="static w-full flex-auto overflow-y-auto bg-transparent">
        <CommandList className="flex flex-auto flex-col overflow-y-auto [&>*]:flex [&>*]:flex-auto [&>*]:flex-col">
          <CommandGroup className={cn("p-0")}>
            <div className={cn("mb-3 flex items-center p-2 text-stone-100/70")}>
              <InlineSVG src={BuildingIcon} className="mr-2 h-4 w-4" />
              <span>Workspace settings</span>
            </div>
            {workspaceItems.map((item) => (
              <Link
                to={item.url}
                key={item.label}
                from="/$slug/settings"
                params={{ slug: currentOrg.slug }}
              >
                <DrawerClose className="w-full">
                  <CommandItem
                    className={cn(
                      commandInputStyles,
                      item.url.includes(menuCategoryName)
                        ? "bg-violet-900 text-white aria-selected:bg-violet-900 aria-selected:text-white"
                        : "",
                    )}
                  >
                    <InlineSVG src={item.icon} className="h-5 w-5 min-w-5 text-white" />
                    <span className={cn("whitespace-nowrap text-white")}>
                      {item.label}
                    </span>
                    <InlineSVG
                      src={ChevronRightIcon}
                      className={cn("ml-auto h-5 w-5 min-w-5")}
                    />
                  </CommandItem>
                </DrawerClose>
              </Link>
            ))}

            <div className="py-2" />
            <div className={cn("mb-3 flex items-center p-2 text-stone-100/70")}>
              <InlineSVG src={UserCogIcon} className="mr-2 h-4 w-4" />
              <span>My Account</span>
            </div>
            {profileItems.map((item) => (
              <Link
                to={item.url}
                key={item.label}
                from="/$slug/settings"
                params={{ slug: currentOrg.slug }}
              >
                <DrawerClose className="w-full">
                  <CommandItem
                    className={cn(
                      commandInputStyles,
                      item.url.includes(menuCategoryName)
                        ? "bg-violet-900 text-white aria-selected:bg-violet-900 aria-selected:text-white"
                        : "",
                    )}
                  >
                    <InlineSVG src={item.icon} className="h-5 w-5 min-w-5 text-white" />
                    <span className={cn("whitespace-nowrap text-white")}>
                      {item.label}
                    </span>
                    <InlineSVG
                      src={ChevronRightIcon}
                      className={cn("ml-auto h-5 w-5 min-w-5")}
                    />
                  </CommandItem>
                </DrawerClose>
              </Link>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </div>
  )
}
