import type { ControllerRenderProps } from "react-hook-form"

import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@fourel/ui"

import type { QuoteFormType } from "../page.js"

interface CurrencySelectProps {
  field: ControllerRenderProps<QuoteFormType, "currency">
  setValue: (field: "currency", value: string) => void
}

const currencyData = [
  { id: "usdCurrencyId1", value: "USD", name: "USD" },
  { id: "eurCurrencyId2", value: "EUR", name: "EUR" },
]

export const CurrencySelect = ({ field, setValue }: CurrencySelectProps) => {
  return (
    <FormItem className="w-1/2">
      <FormLabel className="text-sm">Currency:</FormLabel>
      <div className="flex items-center justify-between gap-2">
        <FormControl>
          <Select
            defaultValue={field.value}
            onValueChange={(value) => {
              setValue("currency", value)
            }}
          >
            <SelectTrigger className="w-full text-xs">
              <SelectValue placeholder="Currency" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup className="cursor-pointer [&>*]:cursor-pointer [&>*]:text-xs">
                {currencyData.map((item) => (
                  <SelectItem key={item.id} value={item.value}>
                    {item.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </FormControl>
      </div>
      <FormMessage className="text-xs" />
    </FormItem>
  )
}
