import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation } from "@tanstack/react-query"
import type { UseFormReturn } from "react-hook-form"

import {
  Button,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  toast,
  ToastAction,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { flightQueryKeys } from "#pages/flights/flight-query-keys.js"
import { queryClient } from "#query-client"

import type { FlightFollowUpListFormType } from "../../flights-list.js"

const FlightNumberUpdateDocument = graphql(/* GraphQL */ `
  mutation FlightNumberUpdate($input: MutationFlightNumberUpdateInput!) {
    flightNumberUpdate(input: $input) {
      id
    }
  }
`)

export const EditFlightNumber = ({
  flightId,
  flightIndex,
  form,
}: {
  flightId?: string | null
  flightIndex: number
  form: UseFormReturn<FlightFollowUpListFormType>
}) => {
  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof FlightNumberUpdateDocument>) =>
      client.request(FlightNumberUpdateDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [flightQueryKeys.OrganizationFlights],
      })
      void queryClient.invalidateQueries({
        queryKey: [flightQueryKeys.FlightNumber, flightId],
      })
      toast({
        title: "Success!",
        description: "Flight number has been updated successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while updating a Flight number.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const onSubmitHandler = () => {
    void form.handleSubmit((values: FlightFollowUpListFormType) => {
      const flightNumberFormData = values.flightRegNumber[flightIndex].registrationNumber
      const input = {
        flightId: flightId!,
        flightNumber: flightNumberFormData,
      }
      void mutate({ input })
    })()
  }

  return (
    <FormField
      control={form.control}
      name={`flightRegNumber.${flightIndex}.registrationNumber`}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <div className="flex items-start">
            <FormControl>
              <div className="flex gap-2">
                <Input {...field} placeholder="Enter" className="w-28" />
                <Button type="button" variant="outline" onClick={onSubmitHandler}>
                  OK
                </Button>
              </div>
            </FormControl>
          </div>
          <FormMessage className="pl-1 text-xs" />
        </FormItem>
      )}
    />
  )
}
