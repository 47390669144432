import { Link, useRouterState } from "@tanstack/react-router"
import ChevronRightIcon from "lucide-static/icons/chevron-right.svg"
import SettingsIcon from "lucide-static/icons/settings.svg"
import InlineSVG from "react-inlinesvg"

import {
  cn,
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  DrawerClose,
} from "@fourel/ui"

import { AddMember } from "#components/add-member.js"
import { menuItems } from "#pages/lobby/components/menu-tabs.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

const commandInputStyles =
  "mb-2 cursor-pointer gap-2 p-2 text-violet-500  aria-selected:text-violet-500 aria-selected:bg-violet-700 aria-selected:hover:bg-violet-800 aria-selected:hover:text-white aria-selected:transition-all aria-selected:duration-200 aria-selected:ease-in-out"

export function MobileMenuTabs({ open }: { open: boolean }) {
  const { currentOrg } = useOnboardedUserInfo()
  const pathname = useRouterState({
    select: (s) => s.location.pathname,
  })
  let menuCategoryName = "no match"
  if (currentOrg && currentOrg.slug && pathname) {
    const menuCategoryRegex = new RegExp(`/${currentOrg.slug}/([^/]+)`)
    const menuCategoryMatch = pathname.match(menuCategoryRegex)
    menuCategoryName = menuCategoryMatch ? menuCategoryMatch[1] : "no match"
  }

  return (
    <div className="flex flex-auto overflow-hidden pl-2 text-sm">
      <Command className="static w-full flex-auto overflow-y-auto bg-transparent">
        <CommandList className="flex flex-auto flex-col overflow-y-auto [&>*]:flex [&>*]:flex-auto [&>*]:flex-col">
          <CommandGroup className={cn("p-0")}>
            {menuItems.map((item) => (
              <Link
                to={item.url}
                key={item.label}
                from="/$slug"
                params={{ slug: currentOrg.slug }}
              >
                <DrawerClose className="w-full">
                  <CommandItem
                    className={cn(
                      commandInputStyles,
                      item.url.includes(menuCategoryName)
                        ? "bg-violet-900 text-white aria-selected:bg-violet-900 aria-selected:text-white"
                        : "",
                    )}
                  >
                    <InlineSVG src={item.icon} className="h-5 w-5 min-w-5 text-white" />
                    <span className={cn("whitespace-nowrap text-white")}>
                      {item.label}
                    </span>
                    <InlineSVG
                      src={ChevronRightIcon}
                      className={cn("ml-auto h-5 w-5 min-w-5")}
                    />
                  </CommandItem>
                </DrawerClose>
              </Link>
            ))}
          </CommandGroup>
          <CommandGroup className={cn("bottom-4 mt-auto p-0")}>
            {currentOrg.isUserAdmin && (
              <CommandItem className={cn(commandInputStyles)}>
                <AddMember open />
              </CommandItem>
            )}
            <CommandItem className={cn(commandInputStyles)}>
              <Link
                className="flex w-full items-center gap-2"
                to="/$slug/settings/members"
                params={{ slug: currentOrg.slug }}
              >
                <InlineSVG src={SettingsIcon} className="h-4 w-4 min-w-5 text-white" />
                <span
                  className={cn(
                    "whitespace-nowrap text-white",
                    !open && "hidden text-[0px]",
                  )}
                >
                  Settings
                </span>
              </Link>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </Command>
    </div>
  )
}
