import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { quotationsRoute } from "#pages/quotations/index.js"

export const combinedQuotes = new Route({
  getParentRoute: () => quotationsRoute,
  path: "/combined-quotes",
  validateSearch: (search: Record<string, unknown>): { tripId: string } => {
    if ("tripId" in search) {
      return {
        tripId: String(search.tripId),
      }
    }
    throw Error("Invalid search parameters")
  },
  component: lazyRouteComponent(() => import("./page.js"), "CombinedQuotes"),
})
