import { useQuery } from "@tanstack/react-query"
import CheckIcon from "lucide-static/icons/check.svg"
import ChevronsUpDownIcon from "lucide-static/icons/chevrons-up-down.svg"
import { useMemo, useState } from "react"
import type { ControllerRenderProps } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContentCombobox,
  PopoverTrigger,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"

import type { TripFormType } from "./quick-trip-request.js"

interface ClientType {
  id: string
  name: string
}

interface ClientSelectProps {
  field: ControllerRenderProps<TripFormType, "clientId">
  setValue: (field: "clientId", value: string) => void
}

const OrganizationClientsDocument = graphql(/* GraphQL */ `
  query OrganizationClientsQuickRequest {
    organizationClients {
      id
      name
    }
  }
`)

export const ClientSelect = ({ field, setValue }: ClientSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { data: queryData } = useQuery({
    queryKey: ["dashboardOrgClients"],
    queryFn: () => client.request(OrganizationClientsDocument),
  })

  const data: ClientType[] = useMemo(
    () =>
      queryData?.organizationClients.map((it) => ({
        id: it.id,
        name: `${it.name}`,
      })) ?? [],
    [queryData],
  )

  const clientSelectHandler = (item: ClientType) => {
    setValue("clientId", item.id)
    setIsOpen(false)
  }

  return (
    <FormItem className="flex w-full flex-col items-center">
      <FormLabel className="w-full text-xs font-medium text-gray-500">Client</FormLabel>
      <div className="flex w-full flex-col">
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                variant="outline"
                role="combobox"
                className={cn(
                  "w-full cursor-pointer justify-between text-xs",
                  !field.value && "text-muted-foreground",
                )}
              >
                {field.value
                  ? data.find((item) => item.id === field.value)?.name
                  : "Client"}
                <InlineSVG
                  src={ChevronsUpDownIcon}
                  className="ml-2 size-4 shrink-0 opacity-50"
                />
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContentCombobox className="w-full p-0">
            <Command>
              <CommandInput placeholder="Search client" className="placeholder:text-xs" />
              <CommandEmpty>No client found.</CommandEmpty>
              {data.map((item) => (
                <CommandItem
                  value={item.name}
                  key={item.id}
                  onSelect={() => clientSelectHandler(item)}
                  className="cursor-pointer text-xs"
                >
                  <InlineSVG
                    src={CheckIcon}
                    className={cn(
                      "mr-2 size-4",
                      item.id === field.value ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {item.name}
                </CommandItem>
              ))}
            </Command>
          </PopoverContentCombobox>
        </Popover>
        <FormMessage className="pl-1 text-xs" />
      </div>
    </FormItem>
  )
}
