import { Avatar, AvatarFallback, AvatarImage, cn } from "@fourel/ui"

import { Config } from "#config-fe.js"
import type { PreferencesByOrganizationIdsQuery } from "#gql/graphql.js"
import type { ExtractTypeFromPath } from "#utils/query-type-extractor.js"

export type Preference = ExtractTypeFromPath<
  PreferencesByOrganizationIdsQuery,
  "preferencesByOrganizationIds"
>

export const renderOrganizationAvatarByOrgId = ({
  preferences,
  organizationId,
  organizationName,
  customStyle,
}: {
  preferences: Preference[]
  organizationId: string
  organizationName: string
  customStyle?: string | null
}) => {
  const preference = preferences.find((item) => item.organizationId === organizationId)

  return (
    <Avatar className={cn(customStyle)}>
      <AvatarImage
        src={`${Config.VITE_PUBLIC_FILES_URL}/organizations/${organizationId}/preferences/${preference?.organizationAvatar}`}
        alt="avatar"
      />
      <AvatarFallback className={cn(customStyle, "text-black")}>
        {organizationName.slice(0, 2).toUpperCase()}
      </AvatarFallback>
    </Avatar>
  )
}
