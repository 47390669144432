import type { VariablesOf } from "@graphql-typed-document-node/core"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import Bot from "lucide-static/icons/bot.svg"
import React from "react"
import { useForm } from "react-hook-form"
import InlineSVG from "react-inlinesvg"
import * as z from "zod"

import {
  Button,
  cn,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Textarea,
  toast,
  ToastAction,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { useParsedTripStore } from "#store/quick-trip-store.js"

const reqSchema = z.object({
  request: z.string().min(10, {
    message: "Request must be at least 10 characters",
  }),
})

type ReqSchemaType = z.infer<typeof reqSchema>

const TripParseDocument = graphql(/* GraphQL */ `
  mutation TripParse($input: MutationTripParseInput!) {
    tripParse(input: $input) {
      clientId
      clientName
      requestedLegs {
        aircraftClass
        arrivalDate
        departureDate
        pax
        arrivalAirports {
          id
          name
          icaoCode
          iataCode
          status
        }
        departureAirports {
          id
          name
          icaoCode
          iataCode
          status
        }
      }
    }
  }
`)

export const AIRequest = () => {
  const form = useForm<ReqSchemaType>({
    resolver: zodResolver(reqSchema),
    defaultValues: {
      request: "",
    },
    reValidateMode: "onSubmit",
  })

  const { setParsedTrip } = useParsedTripStore()

  const { mutate, isPending } = useMutation({
    mutationFn: (variables: VariablesOf<typeof TripParseDocument>) =>
      client.request(TripParseDocument, variables),
    onSuccess: (data) => {
      setParsedTrip(data.tripParse)
      toast({
        title: "Success!",
        description: "Trip hsa been parsed successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while parsing the trip.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const onSubmitHandler = (values: ReqSchemaType) => {
    mutate({ input: { prompt: values.request } })
  }

  return (
    <div className="sticky top-0 flex flex-col gap-2">
      <h4 className="text-xl">AI parsing</h4>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmitHandler)}
          className="flex w-full flex-col items-start justify-start gap-3"
        >
          <FormField
            control={form.control}
            name="request"
            render={({ field }) => (
              <FormItem className="flex w-full items-center">
                <div className="flex w-full flex-col gap-1 pt-[2px]">
                  <FormControl>
                    <Textarea
                      disabled={isPending}
                      {...field}
                      className="min-h-96 w-full"
                    />
                  </FormControl>
                  <FormMessage className="pl-1 text-xs" />
                </div>
              </FormItem>
            )}
          />
          <Button
            variant="outline"
            type="submit"
            disabled={isPending}
            className="flex items-center gap-2"
          >
            <InlineSVG src={Bot} className={cn("h-6", isPending && "animate-bounce")} />
            Pars{isPending ? "ing" : "e"}
          </Button>
        </form>
      </Form>
    </div>
  )
}
