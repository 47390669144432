import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import ChevronRight from "lucide-static/icons/chevron-right.svg"
import React from "react"
import InlineSVG from "react-inlinesvg"

import { Button, SeparatorWithChildren } from "@fourel/ui"

import { formatAirportCode } from "#components/format-airport-code.js"
import {
  formatCustomDate,
  formatCustomLocalDate,
  formatCustomLocalTime,
} from "#components/formated-date.js"
import { graphql } from "#gql"
import { client } from "#graphql-client"
import PlaneIcon from "#pages/trip-requests/icons/plane.svg"
import { useOnboardedUserInfo } from "#store/user-info.js"

const FlightsOrganizationByDateDocument = graphql(/* GraphQL */ `
  query FlightsOrganizationByDateDashboard($input: QueryFlightsOrganizationTodayInput!) {
    flightsOrganizationToday(input: $input) {
      id
      quoteLeg {
        localArrivalDateTime
        localDepartureDateTime
        departureDate
        arrivalDate
        pax
        departureAirport {
          icaoCode
          iataCode
        }
        arrivalAirport {
          icaoCode
          iataCode
        }
      }
      client {
        name
      }
    }
  }
`)

export const Flights = () => {
  const navigate = useNavigate()
  const { currentOrg } = useOnboardedUserInfo()
  const { data } = useQuery({
    queryKey: ["DashboardFlights"],
    queryFn: () =>
      client.request(FlightsOrganizationByDateDocument, {
        input: {
          date: new Date().toISOString(),
          endDate: new Date().toISOString(),
        },
      }),
  })

  const onClickButton = ({ flightId }: { flightId: string }) => {
    void navigate({
      to: `/$slug/flights`,
      params: { slug: currentOrg.slug },
      search: { flightId },
    })
  }

  return (
    <div className="flex flex-col gap-2 rounded-md border p-2">
      <h4 className="text-xl">Today’s flight</h4>
      <>
        {data?.flightsOrganizationToday.length ? (
          <div className="flex flex-col gap-3">
            {data?.flightsOrganizationToday.map((fl) => (
              <div
                key={fl.id}
                className="flex justify-between gap-4 rounded-md bg-slate-100 px-3 py-2"
              >
                <div className="flex gap-4">
                  <div className="font-semibold">{fl.client.name}</div>
                  <div className="flex w-1/2 gap-3">
                    <h3 className="text-normal text-normal flex flex-col text-slate-900">
                      {formatAirportCode(fl.quoteLeg.departureAirport)}
                      <div className="flex gap-2 text-slate-500">
                        <div className="flex flex-col gap-2">
                          <span className="text-nowrap text-xs">
                            {formatCustomDate(fl.quoteLeg.departureDate)}
                          </span>
                          <span className="text-xs">
                            UTC {fl.quoteLeg.departureDate.split("T")[1].slice(0, 5)}
                          </span>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="text-nowrap text-xs">
                            {formatCustomLocalDate(fl.quoteLeg.departureDate)}
                          </span>
                          <span className="text-xs">
                            LOC {formatCustomLocalTime(fl.quoteLeg.departureDate)}
                          </span>
                        </div>
                      </div>
                    </h3>
                    <div className="flex w-full self-center">
                      <SeparatorWithChildren
                        children={<InlineSVG src={PlaneIcon} />}
                        className="flex-1 bg-slate-500"
                      />
                    </div>
                    <h3 className="text-normal flex flex-col font-normal text-slate-900">
                      {formatAirportCode(fl.quoteLeg.arrivalAirport)}
                      <div className="flex gap-2 text-slate-500">
                        <div className="flex flex-col gap-2">
                          <span className="text-nowrap text-xs">
                            {formatCustomDate(fl.quoteLeg.arrivalDate)}
                          </span>
                          <span className="text-xs">
                            UTC {fl.quoteLeg.arrivalDate.split("T")[1].slice(0, 5)}
                          </span>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="text-nowrap text-xs">
                            {formatCustomLocalDate(fl.quoteLeg.arrivalDate)}
                          </span>
                          <span className="text-xs">
                            LOC {formatCustomLocalTime(fl.quoteLeg.arrivalDate)}
                          </span>
                        </div>
                      </div>
                    </h3>
                  </div>
                </div>
                <div className="flex items-center">
                  <Button
                    onClick={() => onClickButton({ flightId: fl.id })}
                    type="button"
                    variant="ghost"
                  >
                    <InlineSVG src={ChevronRight} className="h-8" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>No Flights for today</div>
        )}
      </>
    </div>
  )
}
