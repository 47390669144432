import { maskitoTransform } from "@maskito/core"
import { maskitoTimeOptionsGenerator } from "@maskito/kit"
import React from "react"
import type { ControllerRenderProps, UseFormReturn } from "react-hook-form"

import { FormControl, FormItem, FormLabel, FormMessage, Input } from "@fourel/ui"

import type { TripFormType } from "./quick-trip-request.js"

interface TimeSelectProps {
  field: ControllerRenderProps<TripFormType, `legs.${number}.estimatedTimeOfDeparture`>
  fieldName: `legs.${number}.estimatedTimeOfDeparture`
  form: UseFormReturn<TripFormType>
  index: number
}

export const TimeInput = ({ field, fieldName, form, index }: TimeSelectProps) => {
  const options = maskitoTimeOptionsGenerator({ mode: "HH:MM" })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.value = maskitoTransform(event.currentTarget.value, options)
    form.setValue(fieldName, event.currentTarget.value)

    void form.trigger(fieldName)
  }

  const handleBlur = () => {
    if (index > 0) {
      const prevTimeFieldName = `legs.${index - 1}.estimatedTimeOfDeparture` as const
      const prevDateFieldName = `legs.${index - 1}.departureDate` as const

      const currentTimeFieldName = fieldName
      const currentDateFieldName = `legs.${index}.departureDate` as const

      const prevTime = form.getValues(prevTimeFieldName)
      const prevDate = form.getValues(prevDateFieldName)

      const currentTime = form.getValues(currentTimeFieldName)
      const currentDate = form.getValues(currentDateFieldName)

      if (
        prevTime &&
        currentTime &&
        prevTime === currentTime &&
        prevDate &&
        currentDate &&
        prevDate === currentDate
      ) {
        form.setError(fieldName, {
          type: "manual",
          message:
            "The departure time cannot be the same as the previous leg on the same date.",
        })
      } else if (currentTime) {
        form.clearErrors(fieldName)
      }
    }

    field.onBlur()
  }

  return (
    <FormItem className="flex w-20 flex-col items-start gap-2 space-y-0">
      <FormLabel className="text-xs font-medium text-gray-500">Time</FormLabel>
      <div className="flex w-full flex-col">
        <FormControl>
          <Input
            value={field.value || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text-xs placeholder:text-xs"
            placeholder="HH:MM"
          />
        </FormControl>
        <FormMessage className="text-xs" />
      </div>
    </FormItem>
  )
}
