export const formatCustomDate = (dateString: string): string => {
  if (!dateString) return "No date"
  return new Date(dateString)
    .toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      timeZone: "UTC",
    })
    .toUpperCase()
}

export const formatCustomLocalDate = (dateString: string): string => {
  if (!dateString) return "No date"
  return new Date(dateString)
    .toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .toUpperCase()
}

export const formatCustomLocalTime = (dateString: string): string => {
  if (!dateString) return "No time"
  return new Date(dateString).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })
}
